import theme from 'core/theme';
import _ from 'lodash/fp';

const currentTheme = {
  logoStyles: {
    backgroundSize: 'contain',
    width: 175
  },
  palette: {
    primary: {
      main: '#3F4CD0',
    },
  },
  categoryWrapperActive: '#C8D2F5',
  leftSidebarBg: '#F1F3FD',
  overrides: {
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover': {
          '&:hover': {
            backgroundColor: '#C8D2F5',
          }
        },
      },
    },
    MuiSelect: {
      root: {
        background: '#F1F3FD',
      },
    },
    MuiButton: {
      root: {
        background: '#F1F3FD',
      },
      containedPrimary: {
        background: '#3F4CD0',
      },
      outlinedPrimary: {
        color: '#3F4CD0',
      }
    },
    MuiPaginationItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#3F4CD0',
          '&:hover': {
            backgroundColor: '#3F4CD0',
          }
        },
        '&:hover': {
          backgroundColor: '#F1F3FD',
        }
      }
    },
  }
};

const mergeTheme = _.merge(theme, currentTheme);

export default mergeTheme;
