import config from 'config';
import { getUIFilters } from 'actions/app';
import { getUnreadInboxCount } from './actions/inbox';
import { getUnreadMessageCount } from './actions/messages';
import { getMyUnreadTaskCount, getUnitUnreadTaskCount } from './actions/task';

export const access = null;

export const initActions = {
    ...(config.useUIFilters ? { getUIFilters } : {}),
    getUnreadInboxCount,
    getUnreadMessageCount,
    getMyUnreadTaskCount,
    getUnitUnreadTaskCount
};

export { default as modules } from 'modules';
export { default as reducers } from 'reducers';
