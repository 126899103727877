import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-translate';
import { bindActionCreators } from 'redux';
import {
  MenuList,
  MenuItem,
  Switch,
  FormControlLabel
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import UserName from 'components/Auth/UserName';
import { logout, toggleDebugMode } from 'actions/auth';
import checkAccess from 'helpers/checkAccess';
import config from 'config';
import { history } from 'store';

const {
  application: { type: applicationType },
  cabinetUrl,
  adminPanelUrl,
} = config;
const { origin } = window.location;
const isAdmin = origin === adminPanelUrl;
const isCabinet = origin === cabinetUrl;

const styles = (theme) => ({
  userName: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '.1px',
    margin: '0 0 4px 0',
    color: '#000'
  },
  subTitle: {
    fontSize: 10,
    maxWidth: '135px',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  iconButtonAvatar: {
    padding: 0,
    outlineOffset: 3
  },
  avatar: {
    width: 24,
    height: 24,
  },
  menuLink: {
    textDecoration: 'none',
  },
  outerLinkRoot: {
    padding: 0,
  },
  outerLink: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    width: '100%',
    height: '100%',
    padding: '6px 16px',
  },
  menuListRoot: {
    padding: 0,
    display: 'flex',
    '& > li': {
      textDecoration: 'underline',
      color: theme?.linksColor || '#0068FF',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '.4px',
      padding: 0,
      outline: 'none',
      minHeight: 'auto',
      '&:not(:last-child)': {
        marginRight: 18,
        '&:before': {
          display: 'block',
          width: 2,
          height: 16,
          backgroundColor: '#C8C8C8',
          content: '""',
          position: 'absolute',
          right: '-9px'
        }
      },
      '&:hover': {
        backgroundColor: 'transparent'
      } 
    }
  },
});

class ProfileAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleMenuOpen = ({ currentTarget }) =>
    this.setState({ anchorEl: currentTarget });

  handleMenuClose = () => this.setState({ anchorEl: null });

  handleLogout = () => {
    const { actions } = this.props;
    this.handleMenuClose();
    actions.logout(true);
  };

  renderOuterLink = (url, title) => {
    const { classes } = this.props;

    return (
      <MenuItem
        classes={{
          root: classes.outerLinkRoot,
        }}
      >
        <a
          href={url}
          className={classes.outerLink}
          target="_blank"
          rel="noopener noreferrer"
          tabIndex={0}
          aria-label={title}
        >
          {title}
        </a>
      </MenuItem>
    );
  };

  render() {
    const { t, classes, userInfo, userUnits, debugMode, actions } = this.props;

    const userIsGod = checkAccess({ userIsGod: true }, userInfo, userUnits);
    const userIsAdmin = checkAccess({ userIsAdmin: true }, userInfo, userUnits);

    return (
      <>
        <div className={classes.root}>
          <p className={classes.userName}><UserName {...userInfo} /></p>
          <MenuList
                classes={{
                  root: classes.menuListRoot,
                }}
              >
                {applicationType !== 'adminpanel' ? (
                  <MenuItem
                    autoFocus={true}
                    aria-label={t('MyProfile')}
                    tabIndex={0}
                    onClick={() => {
                      history.push('/profile');
                      this.handleMenuClose();
                    }}
                  >
                    {t('MyProfile')}
                  </MenuItem>
                ) : null}
                {userIsGod && userIsAdmin ? (
                  <>
                    {isAdmin
                      ? this.renderOuterLink(cabinetUrl, t('ToCabinet'))
                      : null}
                    {isCabinet
                      ? this.renderOuterLink(adminPanelUrl, t('ToAdminPanel'))
                      : null}
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={debugMode}
                            onChange={actions.toggleDebugMode}
                          />
                        }
                        label={t('DebugMode')}
                      />
                    </MenuItem>
                  </>
                ) : null}
                <MenuItem
                  tabIndex={0}
                  aria-label={t('Logout')}
                  onClick={this.handleLogout}
                >
                  {t('Logout')}
                </MenuItem>
              </MenuList>
        </div>
      </>
    );
  }
}

ProfileAppBar.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  userUnits: PropTypes.array,
};

ProfileAppBar.defaultProps = {
  userInfo: {},
  userUnits: [],
};

const mapStateToProps = ({
  auth: { info: userInfo, userUnits, debugMode },
}) => ({ userInfo, userUnits, debugMode });
const mapDispatchToProps = (dispatch) => ({
  actions: {
    logout: bindActionCreators(logout, dispatch),
    toggleDebugMode: bindActionCreators(toggleDebugMode, dispatch),
  },
});

const styled = withStyles(styles)(ProfileAppBar);
const translated = translate('Navigator')(styled);
export default connect(mapStateToProps, mapDispatchToProps)(translated);
